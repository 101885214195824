import React from "react";
import { Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import CookbookSection from "../../components/cookbookSection";
import DutchOvenCookingTipsLink from "../../components/dutchOvenCookingTipsLink";

const DutchOvenMainMealsCookbookSection = ({ location }) => {
  return (
    <CookbookSection
      location={location}
      sectionLink="dutch-oven-main-meals"
      sectionName="Main Meals for Dutch Oven"
      sectionTitle="Main Meals for Dutch Oven"
      prevLink="/cookbook/main-meals/"
      nextLink="/cookbook/sandwiches/"
    >
      <Row className="d-flex pb-4 pt-4 flex-wrap justify-content-center" id="cookbook-top-text">
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div>Make a hearty meal with a Dutch oven</div>
          <div className="pt-2">
            <StaticImage width={300} src="../../images/cookbook/dutch-oven-on-tripod.jpg" alt="Dutch Oven in fire" />
          </div>
        </div>
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div className="flex-fill">... you will burn it off tomorrow</div>
          <div className="pt-2">
            <StaticImage width={300} src="../../images/cookbook/tents-with-trees.jpg" alt="Tent with Hammock" />
          </div>
        </div>
      </Row>
      <DutchOvenCookingTipsLink />
    </CookbookSection>
  );
};

export default DutchOvenMainMealsCookbookSection;
